import set from "lodash/set";
import get from "lodash/get";
import isUndefined from "lodash/isUndefined";
import startCase from "lodash/startCase";
import mappers from "./omniture-mapper";
import { dataLayerVarName, channel, pageCategories, paths, loggedInStatus } from "./omniture-consts";
import { getCatalogueAndCategory, capitalize, checkCartAndLoginCookie } from "../utils/common";
import * as consts from "./omniture-consts";

let stateInUtility;
let catalogue;
let rootGeoId;
/**
 * Utility function set state in a global variable
 *
 * @param {object} stateForUtility info related to user login.
 */
function setStateInOmnitureUtility(stateForUtility) {
  stateInUtility = stateForUtility;
  catalogue = get(stateInUtility, "appConfigs.catalogId") || "india";
  rootGeoId = get(stateInUtility, "appConfigs.rootGeoId") || "IN";
}

const defaultState = {
  catalogue: catalogue || "india",
  channel: channel.HOME,
  pageCategory: pageCategories.HOME,
};

/**
 * Utility function to get login status
 *
 * @param {object} userLoginInfo info related to user login.
 * @returns {string} login status.
 */
const getLoginStatus = (userLoginInfo) => {
  const loginStatus = userLoginInfo?.loggedin ? loggedInStatus.LOGGED_IN : loggedInStatus.GUEST;
  return loginStatus;
};

/**
 * This method sets up the user/custData configuration.
 *
 * @param {object} userLoginInfo - user logged-in details fetch from store
 *
 * @returns {object} userData
 */
function getUser(userLoginInfo) {
  const userData =
    userLoginInfo && userLoginInfo.loggedin
      ? mappers.state2user(userLoginInfo.oracleContactId1, userLoginInfo.userLoginId, userLoginInfo.phone)
      : mappers.state2user();

  return userData;
}

/**
 * This method will instantiate the AdobeData global object.
 *
 */
function setAdobeData() {
  window[dataLayerVarName] = window[dataLayerVarName] || [];
}

/**
 * This is a utility method to set page load event data in adobeDataLayer
 *
 * @param {object} data - data object w.r.t specified path param
 */
function setPageLandedData(data) {
  const userLoginInfo = get(stateInUtility, "userDetails.checkLoginInfo");
  const { pathname } = new URL(window.location.href);
  const mainRoute = pathname.split("/")[1];
  const isLoggedIn = ["account", "checkout", "cartpreview"].includes(mainRoute)
    ? userLoginInfo?.loggedin
    : checkCartAndLoginCookie();
  const validCustId = data.custdata.emailID || data.custdata.mobNo;
  const enableDataPush = (!isLoggedIn && !validCustId) || (isLoggedIn && validCustId);
  if (enableDataPush) {
    window[dataLayerVarName].push({
      event: "landed",
      eventype: "landed",
      ...data,
    });
  }
}

/**
 * This method captures common page data for adobeDataLayer
 *
 * @param {string} pageChannel page channel
 * @param {string} catalogId page catalog id
 * @param {string} pageCategory page category
 * @param {Array} levelOfData page level of data
 * @param {object} searchOptions page search options
 * @param {string} pageLevel page level
 */
const setCommonPageLandedData = (
  pageChannel = "",
  catalogId = "",
  pageCategory = "",
  levelOfData = "",
  searchOptions = "",
  pageLevel = "",
) => {
  const userLoginInfo = get(stateInUtility, "userDetails.checkLoginInfo");
  const custdata = getUser(userLoginInfo);
  const loginStatus = getLoginStatus(userLoginInfo);
  const gateData = get(stateInUtility, "gateDetails.gateData");
  const lastSelectedPincode = get(stateInUtility, "gateDetails.lastSelectedPincode");
  const isHomeAutoGateOpen = window.localStorage.getItem("auto-gate-open-home");
  const isAutoGateEnabledOnDesktopHome = get(
    stateInUtility,
    "gateDetails.autoGatePopupConfig.isAutoGateEnabledOnDesktopHome",
  );
  const isAutoGateEnabledOnMobileHome = get(
    stateInUtility,
    "gateDetails.autoGatePopupConfig.isAutoGateEnabledOnMobileHome",
  );
  const pageData = mappers.state2page(
    rootGeoId,
    pageChannel,
    catalogId,
    pageCategory,
    levelOfData,
    loginStatus,
    searchOptions,
    pageLevel,
  );

  if (lastSelectedPincode) {
    pageData.pincodeStatus = consts.GATE_POPUP_TRACKING.PINCODE_RETAINED;
  }

  const pageLandedData = {
    [paths.CUSTOMER_DATA]: custdata,
    [paths.PAGE]: pageData,
  };

  if (
    window.fnpPageType === "home" &&
    !lastSelectedPincode &&
    isHomeAutoGateOpen &&
    (isAutoGateEnabledOnDesktopHome || isAutoGateEnabledOnMobileHome)
  ) {
    pageLandedData[paths.CATEGORY] = { ...gateData.category };
    pageLandedData[paths.GATE] = { ...gateData.gate };
  }
  setPageLandedData(pageLandedData);
};

/**
 *  This method set common page landed event data
 *
 * @param {string} pageChannel - specifies channel of the pageName (eg: plp, home)
 * @param {string} catalogId - specifies region where application is running (eg: india)
 * @param {string} pageCategory - specifies the category of the page (eg: category for plp page)
 * @param {Array} levelOfData - level of data searched under the categories
 */
const setPageCommonLandedEventData = (
  pageChannel = defaultState.channel,
  catalogId = defaultState.catalogue,
  pageCategory = defaultState.pageCategory,
  levelOfData,
) => {
  setCommonPageLandedData(pageChannel, catalogId, pageCategory, levelOfData);
};

/**
 * This methods returns the AdobeData global object.
 *
 * @returns {object} - returns the AdobeData global object
 */
function getAdobeLayer() {
  return window.dataLayerVarName;
}

/**
 * This is a utility method to set inner data objects inside AdobeData
 *
 * @param {string} path - path where data should be inserted (paths are specified in constants)
 * @param {object} data - data object w.r.t specified path param
 */
function setData(path, data) {
  const adobeLayer = getAdobeLayer();
  set(adobeLayer, path, data);
}

/**
 * This method initialise AdobeData and sets the default configurations.
 * This method will be used only when AdobeData object is undefined.
 */
const initializeAdobeData = () => {
  setAdobeData();
};

/**
 * This method loads up the AdobeData when the application is in product listing page
 *
 * @param {Array} breadcrumbs - array of breadcrumbs
 * @param {object} productLists - contains productList, breadCrumbs, cateory information. This object is fetched from state
 * @param {string} eventName - specifies the event triggered within the application (eg : "product listing")
 * @param {string} pageChannel - specifies channel of the pageName (eg: plp, home)
 * @param {string} catalogId - specifies region where application is running (eg: india)
 * @param {*} pageCategory - specifies the category of the page (eg: category for plp page)
 */
const setProductsListing = async (breadcrumbs, productLists, eventName, pageChannel, catalogId, pageCategory) => {
  const url = new URL(window.location.href);
  const userLoginInfo = get(stateInUtility, "userDetails.checkLoginInfo");
  if (isUndefined(getAdobeLayer())) {
    initializeAdobeData();
  }

  const selectedIntlCity = get(stateInUtility, "productsLists.selectedIntlCity");
  const gateData = get(stateInUtility, "gateDetails");
  const isAutoGateEnabledOnDesktopPLP = gateData?.autoGatePopupConfig?.isAutoGateEnabledOnDesktopPLP;
  const isAutoGateEnabledOnMobilePLP = gateData?.autoGatePopupConfig?.isAutoGateEnabledOnMobilePLP;
  const isPLPAutoGateOpen = window.localStorage.getItem("auto-gate-open-plp");
  const productList = productLists.productsList;
  const { searchOptions } = productLists;
  const { category } = getCatalogueAndCategory(window.location.pathname, rootGeoId);
  const productType = capitalize(category?.join(""))?.replace("Lp", "").trim();
  const categoryDesc = {
    productCategoryId: url.pathname.substring(1),
  };
  const levelOfData =
    breadcrumbs &&
    breadcrumbs.slice(1).map((level) => {
      return level.label;
    });
  const tagDetails = {
    CITY: { display: "" },
    RECIPIENT: { display: "" },
    OCCASION: { display: "" },
    PRODUCT_TYPE: { display: productType },
  };
  const pageLevel = levelOfData;
  const loginStatus = getLoginStatus(userLoginInfo);
  const productData = mappers.state2product(productList, categoryDesc?.productCategoryId);
  const categoryData = mappers.state2Category(categoryDesc, catalogId, tagDetails, url?.searchParams);
  const eventData = mappers.setEvent(eventName);
  const pageData = mappers.state2page(
    rootGeoId,
    pageChannel,
    catalogId,
    pageCategory,
    levelOfData,
    loginStatus,
    searchOptions,
    pageLevel,
  );

  if (gateData?.lastSelectedPincode) {
    pageData.pincodeStatus = consts.GATE_POPUP_TRACKING.PINCODE_RETAINED;
  }

  if (!categoryData?.city && catalogId !== "india") {
    categoryData.city = selectedIntlCity;
  }

  const listingData = {};
  listingData[paths.CATEGORY] = categoryData;
  listingData[paths.CUSTOMER_DATA] = getUser(userLoginInfo);
  listingData[paths.FNP_EVENT] = eventData;
  listingData[paths.PAGE] = pageData;
  listingData[paths.PRODUCT_LIST] = productData;

  if (
    !gateData?.lastSelectedPincode &&
    isPLPAutoGateOpen &&
    (isAutoGateEnabledOnDesktopPLP || isAutoGateEnabledOnMobilePLP)
  ) {
    listingData[paths.CATEGORY] = { ...categoryData, ...gateData.gateData.category };
    listingData[paths.GATE] = { ...gateData.gateData.gate };
  }

  setPageLandedData(listingData);
};

/**
 *  This method loads up the AdobeData when the application is in payment options page
 *
 * @param {boolean} isPaymentOption - page is payment options or not
 */
const setPaymentOptionsData = async (isPaymentOption) => {
  const eventName = isPaymentOption ? consts.eventCategories.PAYMENT_METHOD : consts.eventCategories.MESSAGE_ON_CARD;
  const pageCategory = isPaymentOption ? pageCategories.PAYMENT_OPTIONS : pageCategories.DELIVERY_ADDRESS;
  const linkName = isPaymentOption ? `${startCase(eventName)}s` : startCase(eventName);
  const eventData = mappers.setEvent(eventName);
  const linkData = mappers.state2Link(linkName, pageCategory);

  if (eventData) {
    setData(paths.EVENT, eventData);
  }
  if (isPaymentOption) {
    const { pathname } = new URL(window.location.href);
    const pathArray = pathname.split("/");
    const finalPathName = pathArray[pathArray.length - 1];
    const paymentData = mappers.setPayment(finalPathName);

    setData(paths.PAYMENT, paymentData);
  }
  if (linkData) {
    setData(paths.LINK, linkData);
  }

  setData(paths.PAGE, { site: "react2" });
};

/**
 * This method loads up the AdobeData when the application is in product listing page
 *
 * @param {object} productLists - contains productList, breadCrumbs, cateory information. This object is fetched from state
 * @param {string} eventName - specifies the event triggered within the application (eg : "product listing")
 * @param {string} pageChannel - specifies channel of the pageName (eg: plp, home)
 * @param {string} catalogId - specifies region where application is running (eg: india)
 * @param {*} pageCategory - specifies the category of the page (eg: category for plp page)
 */
const setProductsListingInSearch = (productLists, eventName, pageChannel, catalogId, pageCategory) => {
  const userLoginInfo = get(stateInUtility, "userDetails.checkLoginInfo");
  const loginStatus = getLoginStatus(userLoginInfo);
  const custdata = getUser(userLoginInfo);
  const { searchOptions, total, noSearchResult } = productLists;
  const qs = get(searchOptions.params, "qs");

  const searchData =
    noSearchResult === 0 ? mappers.state2Search(eventName, total, qs) : mappers.state2Search(eventName, 0, qs);
  const pageData = mappers.state2page(
    rootGeoId,
    pageChannel,
    catalogId,
    pageCategory,
    qs,
    loginStatus,
    searchOptions,
    "",
  );

  const searchPageData = {
    [paths.CUSTOMER_DATA]: custdata,
    [paths.PAGE]: pageData,
    [paths.SEARCH]: searchData,
  };

  setPageLandedData(searchPageData);
};
/**
 *  This method loads up the AdobeData when the application is in cart preview page
 *
 * @param {string} eventName - specifies the event triggered within the application (eg : "product listing")
 * @param {string} pageChannel - specifies channel of the pageName (eg: plp, home)
 * @param {string} catalogId - specifies region where application is running (eg: india)
 * @param {*} pageCategory - specifies the category of the page (eg: category for plp page)
 */
const setCartPreview = async (eventName, pageChannel, catalogId, pageCategory) => {
  const userLoginInfo = get(stateInUtility, "userDetails.checkLoginInfo");
  const cartItems = get(stateInUtility, "cartDetails.cartItems");
  const custdata = getUser(userLoginInfo);
  const loginStatus = getLoginStatus(userLoginInfo);
  if (isUndefined(getAdobeLayer())) {
    initializeAdobeData();
  }
  const cartList = mappers.state2Cart(cartItems);
  const eventData = mappers.setEvent(eventName);
  const pageData = mappers.state2page(rootGeoId, pageChannel, catalogId, pageCategory, "", loginStatus, "", "");
  const pageLandeddata = {
    custdata,
    fnpevent: (paths.EVENT, eventData),
    cartdata: (paths.CART, cartList),
    page: (paths.PAGE, pageData),
  };
  if (cartItems) {
    setPageLandedData(pageLandeddata);
  }
};

/**
 * This method sets the Adobe data objects for all plp svn pages and of
 * International home pages.
 *
 * @param {Array} breadcrumbs - breadcrumbs data for svn plp
 * @param {string} eventName - specifies the event triggered within the application (eg : "product listing")
 * @param {string} pageChannel - specifies channel of the pageName (eg: plp, home)
 * @param {string} pageCategory - specifies category of the page like for plp its "category"
 */
const setPlpMicroData = async (breadcrumbs, eventName, pageChannel, pageCategory) => {
  const userLoginInfo = get(stateInUtility, "userDetails.checkLoginInfo");
  if (isUndefined(getAdobeLayer())) {
    initializeAdobeData();
  }
  const { rootCatalogId, catalogId } = get(stateInUtility, "appConfigs");
  const { category } = getCatalogueAndCategory(window.location.pathname, rootGeoId);

  const levelOfData =
    breadcrumbs &&
    breadcrumbs.slice(1).map((level) => {
      return level.label;
    });
  const loginStatus = getLoginStatus(userLoginInfo);
  const productType = capitalize(category?.join(""));
  const pageData = mappers.state2page(
    rootGeoId,
    pageChannel,
    catalogId,
    pageCategory,
    levelOfData,
    loginStatus,
    "",
    "",
  );
  const isInternational = rootCatalogId !== catalogId;
  const eventData = mappers.setEvent(eventName);
  const plpMicroData = {};
  if (!isInternational) {
    const categoryData = {
      catalog: catalogue,
      categoryType: category.join(""),
      city: "",
      occasion: "",
      productType,
      recipient: "",
    };

    plpMicroData[paths.CATEGORY] = categoryData;
    plpMicroData[paths.PRODUCT_LIST] = [];
  }
  plpMicroData[paths.CUSTOMER_DATA] = getUser(userLoginInfo);
  plpMicroData[paths.FNP_EVENT] = eventData;
  plpMicroData[paths.PAGE] = pageData;
  setPageLandedData(plpMicroData);
};

/**
 * This method handles the Adobe data for my account pages
 *
 * @param {string} pageChannel - specifies channel of the page. e.g. myaccount
 * @param {string} catalogId - specifies catalog , eg india
 *
 */
const setMyAccountAdobeData = async (pageChannel, catalogId) => {
  const { pathname } = new URL(window.location.href);
  const pathArray = pathname.split("/");

  const userLoginInfo = get(stateInUtility, "userDetails.checkLoginInfo");
  const custdata = getUser(userLoginInfo);
  const loginStatus = getLoginStatus(userLoginInfo);
  if (isUndefined(getAdobeLayer())) {
    initializeAdobeData();
  }
  const pageData = mappers.state2page(
    rootGeoId,
    pageChannel,
    catalogId,
    pathArray[pathArray.length - 1].toString().replaceAll("-", "").toLowerCase(),
    "",
    loginStatus,
    "",
    pathArray[pathArray.length - 1],
  );

  const pageLandeddata = {
    custdata,
    page: (paths.PAGE, pageData),
  };
  setPageLandedData(pageLandeddata);
};

/**
 * This method loads up the AdobeData when the application is in product description page
 *
 * @param {object} productDetail - contains all the data related to product
 * @param {string} eventName - specifies the event triggered within the application (eg : "product view")
 * @param {string} pageChannel - specifies channel of the pageName (eg: plp, home, pdp)
 * @param {string} catalogIdValue - specifies region where application is running (eg: india)
 * @param {*} pageCategory - specifies the category of the page (eg: category for plp page, pdp page)
 * @param {string} isProductStockAvailable - specifies the value that the product is available in stock or not Y if its in stock
 * @param {string} variantProductId - specifies the currently selected variant product id
 * @param {boolean} isMobile - specifies the mobile or desktop
 */
const setProductData = async (
  productDetail,
  eventName,
  pageChannel,
  catalogIdValue,
  pageCategory,
  isProductStockAvailable,
  variantProductId,
  isMobile,
) => {
  const { primaryCategoryId, catalogId, productName, productPrice, skuCode, productId } = productDetail;
  const urlString = window.location.href;
  const url = new URL(urlString);
  const productPosition = url.searchParams.get("pos") ? Number(url.searchParams.get("pos")) : 0;
  const cityTags = url.searchParams.get("CITY_TAGS") ? url.searchParams.get("CITY_TAGS") : "";
  const recipientTags = url.searchParams.get("RECIPIENT_TAGS") ? url.searchParams.get("RECIPIENT_TAGS") : "";
  const occasionTags = url.searchParams.get("OCCASION_TAGS") ? url.searchParams.get("OCCASION_TAGS") : "";
  const currentProductPrice = productPrice?.price;
  const productString = isMobile
    ? `sku:${skuCode},name:${productName},price:${currentProductPrice}`
    : `[sku:${skuCode},name:${productName},price:${currentProductPrice}]`;
  const userLoginInfo = get(stateInUtility, "userDetails.checkLoginInfo");
  const eventData = mappers.setEvent(eventName);
  const searchOptions = window.location.href;
  const loginStatus = getLoginStatus(userLoginInfo);
  const categoryDesc = { productCategoryId: primaryCategoryId, city: "", occasion: "", productType: "" };
  const searchOptionsArr = {
    CITY: { display: cityTags },
    RECIPIENT: { display: recipientTags },
    OCCASION: { display: occasionTags },
    PRODUCT_TYPE: { display: isMobile ? primaryCategoryId : "" },
  };

  const levelOfData = [productName];

  const categoryData = mappers.state2Category(categoryDesc, isMobile ? undefined : catalogId, searchOptionsArr, "");
  const pageData = mappers.state2page(
    rootGeoId,
    pageChannel,
    catalogIdValue,
    pageCategory,
    levelOfData,
    loginStatus,
    searchOptions,
    "",
    "react",
  );

  const finalPDPAdobeData = {
    productCatalog: catalogId,
    productOOS: isProductStockAvailable === "Y" ? "N" : "Y",
    productPosition,
    productSKU: skuCode,
    productString,
    productTemplate: primaryCategoryId,
    productId: variantProductId || productId,
    baseProductID: productId,
  };

  const pdpData = {};
  pdpData[paths.CATEGORY] = categoryData;
  pdpData[paths.CUSTOMER_DATA] = getUser(userLoginInfo);
  pdpData[paths.FNP_EVENT] = eventData;
  pdpData[paths.PAGE] = pageData;
  pdpData[paths.PRODUCT] = finalPDPAdobeData;
  setPageLandedData(pdpData);
};

/**
 *  Function calls satellite.track method of omniture
 *
 * @param {string} trackEvent name of the track event
 */
// const callSatelliteTrack = (trackEvent) => {
//   if (typeof _satellite !== "undefined") {
//     // eslint-disable-next-line no-undef
//     _satellite.track(trackEvent);
//   }
// };

/**
 * Function to set location lock data based on event to data layer
 *
 * @param {string} eventName name of the event
 * @param {string} data data we need to pass
 */
// const setDataForGateLock = (eventName, data) => {
//   const userLoginInfo = get(stateInUtility, "userDetails.checkLoginInfo");
//   if (isUndefined(getDigitalLayer())) {
//     initializeDigitalData();
//   }
//   setUser(userLoginInfo);
//   const eventData = mappers.setEvent(eventName);
//   const linkName = startCase(eventName);
//   const linkData = mappers.state2Link(linkName, window?.fnpPageType);
//   const pageData = { device: isMobile ? "msite" : "dsite" };
//   if (eventData) {
//     setData(paths.EVENT, eventData);
//   }
//   if (linkData) {
//     setData(paths.LINK, linkData);
//   }
//   switch (eventName) {
//     case consts.LOCATION_LOCK_EVENTS.GATE_BUTTON_CLICKED:
//       if (pageData) {
//         setData(paths.PAGE, pageData);
//       }
//       callSatelliteTrack("generic_link");
//       break;
//     case consts.LOCATION_LOCK_EVENTS.GATE_PIN_CODE_CLICKED:
//       if (pageData) {
//         setData(paths.PAGE, pageData);
//       }
//       callSatelliteTrack("generic_link");
//       break;
//     case consts.LOCATION_LOCK_EVENTS.GATE_PIN_CODE_SELECTED:
//       if (pageData) {
//         pageData.pincode = data;
//         setData(paths.PAGE, pageData);
//       }
//       callSatelliteTrack("generic_link");
//       break;
//     case consts.LOCATION_LOCK_EVENTS.GATE_DONT_KNOW_PIN_CODE_CLICKED:
//       if (pageData) {
//         setData(paths.PAGE, pageData);
//       }
//       callSatelliteTrack("generic_link");
//       break;
//     case consts.LOCATION_LOCK_EVENTS.GATE_DONT_KNOW_PIN_CODE_SELECTED:
//       if (pageData) {
//         pageData.pincode = data;
//         setData(paths.PAGE, pageData);
//       }
//       callSatelliteTrack("generic_link");
//       break;
//     case consts.LOCATION_LOCK_EVENTS.GATE_COUNTRY_CLICKED:
//       if (pageData) {
//         setData(paths.PAGE, pageData);
//       }
//       callSatelliteTrack("generic_link");
//       break;
//     case consts.LOCATION_LOCK_EVENTS.GATE_COUNTRY_SELECTED:
//       if (pageData) {
//         pageData.country = data;
//         setData(paths.PAGE, pageData);
//       }
//       callSatelliteTrack("generic_link");
//       break;
//     case consts.LOCATION_LOCK_EVENTS.GATE_CITY_CLICKED:
//       if (pageData) {
//         setData(paths.PAGE, pageData);
//       }
//       callSatelliteTrack("generic_link");
//       break;
//     case consts.LOCATION_LOCK_EVENTS.GATE_CITY_SELECTED:
//       if (pageData) {
//         pageData.city = data;
//         setData(paths.PAGE, pageData);
//       }
//       callSatelliteTrack("generic_link");
//       break;
//     case consts.LOCATION_LOCK_EVENTS.GATE_CONTINUE_CLICKED:
//       if (pageData) {
//         setData(paths.PAGE, { ...pageData, ...data });
//       }
//       callSatelliteTrack("generic_link");
//       break;
//     case consts.LOCATION_LOCK_EVENTS.GATE_NON_SERVICEABLE_CLICKED:
//       if (pageData) {
//         setData(paths.PAGE, { ...pageData, ...data });
//       }
//       callSatelliteTrack("generic_link");
//       break;
//     case consts.LOCATION_LOCK_EVENTS.GATE_VIEW_AVAILABLE_GIFTS_CLICKED:
//       if (pageData) {
//         setData(paths.PAGE, { ...pageData, ...data });
//       }
//       callSatelliteTrack("generic_link");
//       break;
//     default:
//       break;
//   }
// };

/**
 * Function to set search suggestion data based on event to data layer
 *
 * @param {string} eventName name of the event
 * @param {string} data data we need to pass
 */
// const setDataForSearchSuggestion = (eventName, data) => {
//   const userLoginInfo = get(stateInUtility, "userDetails.checkLoginInfo");
//   if (isUndefined(getDigitalLayer())) {
//     initializeDigitalData();
//   }
//   setUser(userLoginInfo);
//   const eventData = mappers.setEvent(eventName);

//   const linkName = startCase(eventName);
//   const linkData = mappers.state2Link(linkName, window?.fnpPageType);

//   const brand = mappers.calculateBrand(rootGeoId);
//   const pageType = getFnpPageType();
//   const { category } = getCatalogueAndCategory(window.location.pathname, rootGeoId);
//   const pageData = {
//     device: isMobile ? "msite" : "dsite",
//     page_name: `${brand}:${pageType}:${catalogue}`,
//     page_url: removeHashFromURL(window.location.href),
//     page_category: category,
//   };

//   const searchData = {
//     search_type: data.type,
//     search_position: data.position,
//     search_keyword: data.search,
//     user_keyword: data.actualSearch,
//   };
//   if (eventData) {
//     setData(paths.EVENT, eventData);
//   }
//   if (pageData) {
//     setData(paths.PAGE, pageData);
//   }
//   switch (eventName) {
//     case consts.SEARCH_SUGGESTION_EVENTS.SEARCH_SUGGESTION_CLICKED:
//       if (linkData) {
//         setData(paths.LINK, linkData);
//       }
//       if (searchData) {
//         setData(paths.SEARCH, searchData);
//       }
//       callSatelliteTrack("Searchsuggestion_Clicked");
//       break;
//     case consts.SEARCH_SUGGESTION_EVENTS.NO_SEARCH_RESULTS:
//       if (searchData) {
//         delete searchData.search_position;
//         delete searchData.search_type;
//         setData(paths.SEARCH, searchData);
//       }
//       callSatelliteTrack("NoSearchResults");
//       break;
//     default:
//       break;
//   }
// };

/**
 * This is a utility method to set page click event data in adobeDataLayer
 *
 * @param {object} data - contains data
 * @param {object} userLoginInfo - contains user login info data
 * @param {object} pageValues - contains page data
 */
function setOnClickData(data, userLoginInfo, pageValues) {
  const adobeLayer = getAdobeLayer();
  if (isUndefined(adobeLayer)) {
    initializeAdobeData();
  }
  const adobeDataName = adobeLayer?.getState();
  const custdata = getUser(userLoginInfo);
  const loginStatus = getLoginStatus(userLoginInfo);

  const pageData = mappers.state2page(
    rootGeoId,
    pageValues?.channel,
    catalogue || "india",
    pageValues?.pageCategories,
    "",
    loginStatus,
    "",
    "",
  );

  const pageLandedData = {
    [paths.CUSTOMER_DATA]: custdata,
    [paths.PAGE]: pageData,
  };

  const clickData = { ...data };
  clickData.link.linkPageName = adobeDataName?.page?.pageName;

  window[dataLayerVarName].push({
    event: "click",
    eventype: "click",
    ...clickData,
    ...pageLandedData,
  });
}

export {
  initializeAdobeData,
  setProductsListing,
  setProductsListingInSearch,
  setCartPreview,
  setPlpMicroData,
  setMyAccountAdobeData,
  setProductData,
  setStateInOmnitureUtility,
  setPaymentOptionsData,
  // setDataForGateLock,
  // setDataForSearchSuggestion,
  setPageCommonLandedEventData,
  setPageLandedData,
  getLoginStatus,
  getUser,
  setOnClickData,
};
